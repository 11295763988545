<template>
  <div class="reserve-submit">
    <h3 class="title" v-if="!loadStatus && !error.length">
      {{ loading }}
    </h3>
    <h3 class="title error-text" v-if="error.length">
      {{ error }}
    </h3>
    <h3 class="title success-text" v-if="resultMsg.length">
      {{ resultMsg }}
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;

  &.error-text {
    background-color: rgba(red, 0.6);
  }

  &.success-text {
    background-color: rgba(green, 0.6);
  }
}
</style>

<script>
import apiService from "@/api/service.js";

export default {
  name: "ReceiveAllUserPackages",
  data() {
    return {
      loading: "Hold tight, loading...",
      loadStatus: false,
      error: "",
      resultMsg: "",
    };
  },
  mounted() {
    this.reserve();

    setTimeout(() => {
      if (!this.loadStatus) {
        this.loading = "It is taking longer than expected...";

        setTimeout(() => {
          if (!this.loadStatus) {
            this.loading = "Sorry for the inconvenience, still loading...";
          }
        }, 4000);
      }
    }, 2000);
  },
  methods: {
    pollDrawer: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == false) {
          return true;
        }

        return await this.pollDrawer(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawer(drawerId, maxTry, errorCount + 1);
      }
    },
    pollDrawerOpen: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == true) {
          return true;
        }

        return await this.pollDrawerOpen(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawerOpen(drawerId, maxTry, errorCount + 1);
      }
    },
    reserve: async function () {
      let barcode = this.$route.query.barcode;

      if (barcode.length <= 0) {
        this.error =
          "There are one or more empty fields in the form, try again.";
        return;
      }

      try {
        const result = await apiService.getAllUserPackagesByCode(barcode);

        if (!result) {
          throw "No package found!";
        }

        console.log(result);

        this.loadStatus = true;

        if (result.length <= 0) {
          this.resultMsg = "No packages available to withdraw.";

          setTimeout(() => {
            this.$router.push({
              name: "Home",
            });
          }, 15000);
          return;
        }

        // if (!openedDrawers) {
        //   this.error = "";
        //   this.resultMsg = "No awaiting packages are found for you.";
        //   return;
        // }

        this.resultMsg = `Found ${result.length} packages, please wait until the drawers are opened...`;

        let openedDrawers = [];

        for (let i = 0; i < result.length; i++) {
          const packageData = result[i];
          const drawerId = packageData.drawer_id;
          const drawerData = await apiService.getDrawer(drawerId);

          if (drawerData.blackout_state == 1) {
            this.resultMsg = "";
            this.error = `Drawer ${drawerId} is currently in blackout state, please try getting that package later. You will now receive the rest of the packages.`;

            await new Promise((resolve) => setTimeout(resolve, 3000));
            continue;
          }

          try {
            const open = await apiService.openDrawer(drawerId);
          } catch (err) {
            this.resultMsg = "";
            this.error =
              "System error while opening a drawer, please contact system administrator.";
            continue;
          }

          const pollOpen = await this.pollDrawerOpen(drawerId);
          if (!pollOpen) {
            this.resultMsg = "";
            this.error =
              "A drawer is not opened, please try again later or contact system administrator.";
            continue;
          }

          openedDrawers.push(drawerId);

          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        if (openedDrawers.length == result.length) {
          this.resultMsg =
            "All drawers have been opened. Please take your packages.";
        } else {
          this.resultMsg =
            "Some drawers couldn't be opened. Please take your packages and try again later to get other packages.";
        }

        await new Promise((resolve) => setTimeout(resolve, 5000));

        if (!openedDrawers) {
          this.resultMsg = "";
          this.error =
            "No drawers are opened. Please contact system administrator.";
          return;
        }

        let closedDrawers = [];

        openedDrawers.forEach(async (drawerId) => {
          const status = await this.pollDrawer(drawerId);

          if (!status) {
            return;
          }

          closedDrawers.push(drawerId);
        });

        if (openedDrawers.length == 0) {
          this.resultMsg = "";
          this.error =
            "No drawers are opened. Please contact system administrator.";
          return;
        }

        let trials = 0;
        let errorOnClosing = false;

        while (closedDrawers.length < openedDrawers.length) {
          if (trials >= 10) {
            errorOnClosing = true;
            break;
          }

          await new Promise((resolve) => setTimeout(resolve, 3000));
          trials++;
        }

        if (errorOnClosing) {
          this.error = "";
          this.resultMsg = "Please close all the open drawers.";
        }

        if (!errorOnClosing) {
          this.error = "";
          this.resultMsg =
            "All packages are received successfully. Have a nice day!";
        }

        // Send a receive request for each package
        result.forEach(async (packageData) => {
          // If the drawer is not opened, return.

          const drawerData = await apiService.getDrawer(packageData.drawer_id);

          if (!openedDrawers.includes(packageData.drawer_id)) {
            return;
          }

          const packageId = packageData.id;
          const receiveResult = await apiService.receiveByPackageId(packageId);
        });
      } catch (error) {
        console.log(error);
        this.error = "There is a system error occured. Please try again later.";
        return;
      }

      setTimeout(() => {
        this.$router.push({
          name: "Home",
        });
      }, 30000);
      return;
    },
  },
};
</script>
